import { useState } from 'react';

const initialState = {
  name: '',
  email: '',
  message: '',
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setState({ ...initialState });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const to_send = {
      name: name,
      email: email,
      message: message,
    };
    try {
      const response = await fetch('/.netlify/functions/contact-us', {
        method: 'POST',
        body: JSON.stringify(to_send),
      });

      if (!response.ok) {
        //Do something when request fails
        console.log('Error', response);
        setError(true);
        setSubmitting(false);
        return;
      }
      setSuccess(true);
      clearState();
      setSubmitting(false);
      //Do something when request is successful
    } catch (e) {
      console.log(e);
      setError(true);
      setSubmitting(false);
    }
  };
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will get back to you as soon as possible.
                </p>
              </div>
              <form name='contact' validate method='post' data-netlify='true' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        value={name}
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        value={email}
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    value={message}
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <input type='hidden' name='form-name' value='contact' />

                {success ? (
                  <div id='success' className='alert alert-success'>
                    <strong>Your query was successfully submitted. We will contact you very soon.</strong>{' '}
                  </div>
                ) : null}

                {error ? (
                  <div id='error' className='alert alert-danger'>
                    <strong>Error Submitting the message. Please Try Again</strong>
                  </div>
                ) : null}

                <button type='submit' className='btn btn-custom btn-lg' disabled={submitting}>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            {/* <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div> */}
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          {/* <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>&copy; {new Date().getFullYear()} AmpElectro Engineering Solutions.</p>
        </div>
      </div>
    </div>
  );
};
